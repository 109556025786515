<div class="col-12 p-0">
  <div class="row">
    <div class="col-12 col-md-6 m-auto">
      <ng-content></ng-content>
    </div>
    <div class="col-12 col-md-6 text-right">
    </div>
  </div>

  <div class="box row">
    <div class="col-12 col-lg-5">
      <div class="d-flex justify-content-between">
        <label>Project assignee(s) List</label>
        <a class="text-danger pointer text-underline" (click)="onAddAll()">Select All</a>
      </div>
      <div class="user-list text-left d-flex flex-column">
        <div class="input-group mb-2">
          <input type="text" class="form-control" placeholder="Search name"
            (keyup.enter)="getUserList($event.target.value)" (keydown.enter)="$event.preventDefault()">
          <div class="input-group-append">
            <div class="input-group-text">
              <svg-icon src="../../../../../assets/images/icons/search.svg"></svg-icon>
            </div>
          </div>
        </div>
        <ng-container *ngIf="projectManager; else elseTemplate">
          <ng-container *ngIf="!userList; else userTemplate">
            <div class="mt-5 flex-grow-1">
              <app-loading-spinner></app-loading-spinner>
            </div>
          </ng-container>
          <ng-template #userTemplate>
            <div class="flex-grow-1 overflow-auto">
              <div class="py-2 my-1 pointer" [ngClass]="{'active': selected.has(user)}" *ngFor="let user of userList"
                (click)="onSelectUser(user)">
                {{ user.context['full_name'] }}
              </div>
              <ng-container *ngIf="(!userList.size && !addedList.size )|| isSearch">
                Data not found
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #elseTemplate>
          Please select client
        </ng-template>
      </div>
    </div>

    <div class="col-12 col-lg-2 d-flex flex-column justify-content-center my-3">
      <button type="button" class="btn btn-outline-secondary mb-3" (click)="onAddEmployee()">
        Add <svg-icon src="../../../../../assets/images/icons/right.svg"></svg-icon>
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="onRemoveEmployee()">
        <svg-icon src="../../../../../assets/images/icons/left.svg"></svg-icon> Remove
      </button>
    </div>

    <div class="col-12 col-lg-5">
      <div class="d-flex justify-content-between">
        <label>Added Project assignee</label>
        <a class="text-danger pointer text-underline" (click)="onRemoveAll()">Select All</a>
      </div>
      <div class="user-list text-left overflow-auto">
        <div class="py-2 my-1 pointer" [ngClass]="{'active': selected.has(user)}" *ngFor="let user of addedList"
          (click)="onSelectUser(user)">
          {{ user.context['full_name'] }}
        </div>
      </div>
    </div>
  </div>

  <small class="text-danger" *ngIf="projectAssignee.touched && projectAssignee.errors">
    Project assignee is required.
  </small>
</div>