import { ProjectService } from 'src/app/core/services/project.service';
import { Observable, of, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Dropdown } from './../../models/dropdown.model';
import { DropdownService } from './../../../core/services/dropdown.service';
import {
  ExtendedNgbDateAdapter,
  ExtendedNgbDateParserFormatter,
} from './../../dateparser';
import { Component, OnDestroy } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { IFilterParams } from 'ag-grid-community';
import { CustomSearchService } from '../../services/custom-search.service';
import { CustomFilter } from '../../models/custom-filter.model';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { map, takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss'],
})
export class CustomSearchComponent implements AgFilterComponent, OnDestroy {
  componentDestroy$ = new Subject();
  projectId: number;
  search: { [key: string]: any } = {};
  params: IFilterParams;
  customFilter: CustomFilter;
  isOpen = false;
  date: NgbDate;
  dropdown$: Observable<
    (Omit<Dropdown, 'value'> & { value: string | number })[]
  >;
  constantDropdown: { [key: string]: { label: string; value: string }[] } = {
    constant_role: [
      { label: 'Admin', value: 'admin' },
      { label: 'Supervisor', value: 'supervisor' },
    ],
  };

  constructor(
    private searchService: CustomSearchService,
    private calendar: NgbCalendar,
    private formatter: ExtendedNgbDateAdapter,
    private parseDate: ExtendedNgbDateParserFormatter,
    private dropdownService: DropdownService,
    private projectService: ProjectService
  ) {}

  get options(): any[] {
    return this.customFilter?.options;
  }

  get field(): string {
    const keySplit = this.params.colDef.field?.split('.');
    const key = this.customFilter?.field ?? keySplit[keySplit?.length - 1];
    return key;
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  getDropdown(): void {
    if (
      Object.keys(this.constantDropdown).includes(this.customFilter['dropdown'])
    ) {
      this.dropdown$ = of(this.constantDropdown[this.customFilter['dropdown']]);
      return;
    }

    const params = new HttpParams()
      .set('type', this.customFilter['dropdown'])
      .set('project', this.projectId);
    this.dropdown$ = this.dropdownService
      .getDropdown(params)
      .pipe(map((res) => res[this.customFilter['dropdown']]));
  }

  agInit(params: import('ag-grid-community').IFilterParams): void {
    this.projectService.projectSubject
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((project) => !!project)
      )
      .subscribe({
        next: (project) => {
          this.projectId = project.id;
        },
      });
    this.params = params;

    this.searchService.serviceSubject.subscribe((service) => {
      this.customFilter = service;

      if (this.customFilter['dropdown']) {
        this.getDropdown();
      }
    });
  }

  afterGuiAttached?(): void {
    this.isOpen = false;
  }

  isFilterActive(): boolean {
    return !!this.search[this.field];
  }
  doesFilterPass(): boolean {
    return true;
  }
  getModel(): void { var empty; }

  setModel(): void | import('ag-grid-community').AgPromise<void> {}

  updateFilter(): void {
    this.onSearch();
  }

  onDateSelected(event: NgbDate): void {
    this.date = event;
    this.search[this.field] = `${event.year}-${event.month}-${event.day}`;
    this.onSearch();
  }

  onClear(): void {
    this.date = null;
    this.search[this.field] = '';
    this.onSearch();
  }

  onSearch(): void {
    this.isOpen = false;
    const queryParams = {
      [this.field.toLowerCase()]: this.search[this.field] || '',
    };

    if (this.field.includes('after') && this.date) {
      const before = this.calendar.getNext(this.date, 'd', 1);
      queryParams[this.field.replace('after', 'before')] =
        this.formatter.toModel(before);
    }

    this.customFilter.service.querySubject.next(queryParams);
    this.params.filterChangedCallback();
  }

  get getDateString(): string {
    return this.parseDate.format(this.date);
  }
}
