import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Dropdown } from '../../models/dropdown.model';
import { ActivatedRoute } from '@angular/router';
import { DropdownService } from '../../../core/services/dropdown.service';
import { HttpParams } from '@angular/common/http';
import { UserCommonData } from '../../models/user.models';
import { Client } from '../../models/client.model';

@Component({
  selector: 'app-select-project-assignee',
  templateUrl: './select-project-assignee.component.html',
  styleUrls: ['./select-project-assignee.component.scss'],
})
export class SelectProjectAssigneeComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() projectManager: UserCommonData;
  @Input() branchId: number;
  id: number;
  userList: Set<Dropdown>;
  addedList: Set<Dropdown> = new Set([]);
  selected: Set<Dropdown> = new Set([]);
  selectAll = [];

  isSearch = false;

  constructor(
    private route: ActivatedRoute,
    private dropdownService: DropdownService
  ) {}

  ngOnChanges(): void {
    if (this.projectManager) {
      this.getUserList();
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  get projectAssignee(): AbstractControl {
    return this.formGroup.get('project_assignees');
  }

  // get data
  getUserList(query?: string): void {
    this.isSearch = !!query;
    const params = new HttpParams()
      .set('type', 'manage_user')
      .set('no_limit', true)
      .set('branch', this.branchId)
      .set('query', query || '');

    this.userList = null;
    this.dropdownService.getDropdown(params).subscribe((res) => {
      let users = res.manage_user.filter(
        (e) => !this.projectAssignee.value.includes(e.value)
      );

      const selectedProjectAssignee =
        this.formGroup.get('project_assignees').value;
      if (selectedProjectAssignee && !this.addedList.size) {
        users = users.filter((e) => !selectedProjectAssignee.includes(e.value));
        this.addedList = new Set([
          ...res.manage_user.filter((e) =>
            selectedProjectAssignee.includes(e.value)
          ),
        ]);
      }

      this.userList = new Set(users);
      this.selected.clear();
    });
  }

  // event
  onSelectUser(selected: Dropdown): void {
    if (this.selected.has(selected)) {
      this.selected.delete(selected);
      return;
    }
    this.selected.add(selected);
  }

  onAddEmployee(): void {
    if (!this.selected.size) {
      return;
    }
    this.selected.forEach((employee) => {
      this.addedList.add(employee);
      this.projectAssignee.patchValue([
        ...this.projectAssignee.value,
        employee.value,
      ]);
      this.userList.delete(employee);
    });
    this.addedList = this.onSortUser(this.addedList);
    this.selected.clear();
  }

  onRemoveEmployee(): void {
    if (!this.selected.size) {
      return;
    }
    this.selected.forEach((employee) => {
      this.userList.add(employee);
      this.projectAssignee.patchValue([
        ...this.projectAssignee.value.filter((e) => e !== employee.value),
      ]);
      this.addedList.delete(employee);
    });
    this.userList = this.onSortUser(this.userList);
    this.selected.clear();
  }

  onAddAll(): void {
    if (!this.userList.size) {
      return;
    }
    this.userList.forEach((employee) => {
      this.addedList.add(employee);
      this.projectAssignee.patchValue([
        ...this.projectAssignee.value,
        employee.value,
      ]);
    });
    this.addedList = this.onSortUser(this.addedList);
    this.userList.clear();
    this.selected.clear();
  }

  onRemoveAll(): void {
    if (!this.addedList.size) {
      return;
    }
    this.addedList.forEach((employee) => {
      this.userList.add(employee);
      this.projectAssignee.patchValue([
        ...this.projectAssignee.value.filter((e) => e !== employee.value),
      ]);
    });
    this.userList = this.onSortUser(
      new Set([...this.userList, ...this.addedList])
    );
    this.addedList.clear();
    this.selected.clear();
  }

  onSortUser(users: Set<Dropdown>): Set<Dropdown> {
    const userList = [...users];
    return new Set(userList.sort((a, b) => (a.value > b.value ? 1 : -1)));
  }
}
