import {
  MerchandiseSetting,
  MerchandiseQuestion,
  Question,
  MerchandiseLevelDetail,
} from './../../shared/models/client-settings.model';
import {
  LeaveQuota,
  ClientLeaveType,
  WorkingHour,
} from 'src/app/shared/models/client-settings.model';
import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DrfResponse } from 'src/app/shared/models/drf-response.models';
import { ApiUrl } from '../http/api.constant';
import {
  Survey,
  SurveyClientSetting,
} from 'src/app/shared/models/survey-client.model';
import {
  DocumentForm,
  DocumentList,
  UploadForm,
  UploadList,
} from 'src/app/shared/models/upload-document.model';
import { ImportTask } from '../../shared/models/background-task.model';
import { Dropdown } from 'src/app/shared/models/dropdown.model';
import { Training, TrainingClientSetting } from 'src/app/shared/models/training-client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientSettingService {
  onDeleteQuestion: Subject<number>;
  querySubject = new Subject<{ [key: string]: any }>();
  disableForm = false;

  constructor(private http: ApiService, private router: Router) {
    this.onDeleteQuestion = new Subject<number>();
  }

  // Working hours
  getWorkingHours(params?: HttpParams): Observable<DrfResponse<WorkingHour>> {
    return this.http.get<DrfResponse<WorkingHour>>(ApiUrl.working_hours, {
      params: params,
    });
  }

  getWorkingHourDetail(
    id: number,
    params?: HttpParams
  ): Observable<WorkingHour> {
    return this.http.get<WorkingHour>(ApiUrl.working_hours + `${id}/`, {
      params: params,
    });
  }

  createWorkingHour(data: { [key: string]: any }): Observable<WorkingHour> {
    return this.http.post<WorkingHour>(ApiUrl.working_hours, data);
  }

  editWorkingHour(
    id: number,
    data: { [key: string]: any }
  ): Observable<WorkingHour> {
    return this.http.patch<WorkingHour>(ApiUrl.working_hours + `${id}/`, data);
  }

  deleteWorkingHour(id: number): Observable<WorkingHour> {
    return this.http.delete<WorkingHour>(ApiUrl.working_hours + `${id}/`);
  }

  postImportRoutineWorkingHour(
    formData: FormData,
    params?: HttpParams
  ): Observable<ImportTask> {
    return this.http.post<ImportTask>(
      ApiUrl.working_hours + 'routine-import/',
      formData,
      {
        params,
      }
    );
  }

  postImportMonthlyWeeklyWorkingHour(
    formData: FormData,
    params?: HttpParams
  ): Observable<ImportTask> {
    return this.http.post<ImportTask>(
      ApiUrl.working_hours + 'monthly-weekly-import/',
      formData,
      {
        params,
      }
    );
  }

  // Leave quota
  getLeaveQuotas(params?: HttpParams): Observable<DrfResponse<LeaveQuota>> {
    return this.http.get<DrfResponse<LeaveQuota>>(ApiUrl.leave_types, {
      params: params,
    });
  }

  getLeaveQuota(id: number): Observable<LeaveQuota> {
    return this.http.get<LeaveQuota>(ApiUrl.leave_types + `${id}/`);
  }

  getIdOfSettingType(): { client?: number; project?: number } {
    const url = this.router.url;
    const urlParts = url.split('/');
    const result: { client?: number; project?: number } = {};
    urlParts.forEach((part, inx, arr) => {
      if (['project', 'client', 'projects'].includes(part)) {
        const id = +urlParts[inx + 1];
        if (Number.isInteger(id)) {
          if (part === 'project' || part === 'projects') {
            result.project = id;
          } else {
            result.client = id;
          }
          arr.splice(inx + 1, 1);
        }
      }
    });
    return result;
  }

  createLeaveQuota(data: { [key: string]: any }): Observable<LeaveQuota> {
    return this.http.post<LeaveQuota>(ApiUrl.leave_types, data);
  }

  editLeaveQuota(
    id: number,
    data: { [key: string]: any }
  ): Observable<LeaveQuota> {
    return this.http.patch<LeaveQuota>(ApiUrl.leave_types + `${id}/`, data);
  }

  deleteLeaveQuota(id: number): Observable<LeaveQuota> {
    return this.http.delete<LeaveQuota>(ApiUrl.leave_types + `${id}/`);
  }

  getLeaveTypeSettings(id: number): Observable<ClientLeaveType> {
    return this.http.get<ClientLeaveType>(
      ApiUrl.client_leave_type_settings + `${id}/`
    );
  }

  editLeaveTypeSettings(
    clientId: number,
    data: { [key: string]: any }
  ): Observable<ClientLeaveType> {
    return this.http.patch<ClientLeaveType>(
      ApiUrl.client_leave_type_settings + `${clientId}/`,
      data
    );
  }

  editProjectLeaveTypeSettings(
    projectId: number,
    data: { [key: string]: any }
  ): Observable<ClientLeaveType> {
    return this.http.patch<ClientLeaveType>(
      ApiUrl.project_leave_type_settings + `${projectId}/`,
      data
    );
  }

  // OT rules
  createOTRule(data: { [key: string]: any }): Observable<unknown> {
    return this.http.post(ApiUrl.ot_rules, data);
  }

  editOTRule(id: number, data: { [key: string]: any }): Observable<unknown> {
    return this.http.patch(ApiUrl.ot_rules + `${id}/`, data);
  }

  deleteOTRule(id: number): Observable<unknown> {
    return this.http.delete<unknown>(ApiUrl.ot_rules + `${id}/`);
  }

  // Merchandizer settings
  getMerchandizerSettings(
    params: HttpParams
  ): Observable<DrfResponse<MerchandiseSetting>> {
    return this.http.get<DrfResponse<MerchandiseSetting>>(
      ApiUrl.merchandizer_settings,
      { params: params }
    );
  }

  getMerchandizerSetting(
    id: number,
    params?: HttpParams
  ): Observable<MerchandiseSetting> {
    return this.http.get<MerchandiseSetting>(
      ApiUrl.merchandizer_settings + `${id}/`,
      {
        params: params,
      }
    );
  }

  getMerchandizerLevelDetail(
    id: number,
    params?: HttpParams
  ): Observable<MerchandiseLevelDetail> {
    return this.http.get<MerchandiseLevelDetail>(
      ApiUrl.merchandizer_settings + `${id}/`,
      {
        params: params,
      }
    );
  }

  importShopProductInformation(
    file: Blob,
    type: string,
    params?: HttpParams
  ): Observable<ImportTask> {
    const data = new FormData();
    data.set('file', file);
    return this.http.post<ImportTask>(
      ApiUrl.merchandizer_settings + `import-${type}-information/`,
      data,
      { params }
    );
  }

  createMerchandizerSetting(data: {
    [key: string]: any;
  }): Observable<MerchandiseSetting> {
    return this.http.post<MerchandiseSetting>(
      ApiUrl.merchandizer_settings,
      data
    );
  }

  deleteMerchandiseSetting(id: number): Observable<MerchandiseSetting> {
    return this.http.delete<MerchandiseSetting>(
      ApiUrl.merchandizer_settings + `${id}/`
    );
  }

  editMerchandiseSetting(
    id: number,
    data: { [key: string]: any }
  ): Observable<MerchandiseSetting> {
    return this.http.patch<MerchandiseSetting>(
      ApiUrl.merchandizer_settings + `${id}/`,
      data
    );
  }

  //client survey
  createSurveyClient(
    data: SurveyClientSetting
  ): Observable<SurveyClientSetting> {
    return this.http.post<SurveyClientSetting>(
      ApiUrl.survey_client_settings,
      data
    );
  }
  editSurveyClient(
    id: number,
    data: { [key: string]: any }
  ): Observable<SurveyClientSetting> {
    return this.http.patch<SurveyClientSetting>(
      ApiUrl.survey_client_settings + `${id}/`,
      data
    );
  }

  getSurveyClient(
    id: number,
    params?: HttpParams
  ): Observable<SurveyClientSetting> {
    return this.http.get<SurveyClientSetting>(
      ApiUrl.survey_client_settings + `${id}/`
    );
  }

  duplicateSurveyClient(
    id: number,
    data?: { [key: string]: any }
  ): Observable<SurveyClientSetting> {
    return this.http.post<SurveyClientSetting>(
      ApiUrl.survey_client_settings + `${id}/duplicate/`,
      data
    );
  }

  getSurveyClients(params: HttpParams): Observable<DrfResponse<Survey>> {
    return this.http.get<DrfResponse<Survey>>(ApiUrl.survey_client_settings, {
      params: params,
    });
  }

  deleteSurveyClient(id: number): Observable<Survey> {
    return this.http.delete<Survey>(ApiUrl.survey_client_settings + `${id}/`);
  }

  //create training
  createTrainingClient(
    data: TrainingClientSetting
  ): Observable<TrainingClientSetting> {
    return this.http.post<TrainingClientSetting>(
      ApiUrl.training_client_settings,
      data
    );
  }

  editTrainingClient(
    id: number,
    data: { [key: string]: any }
  ): Observable<TrainingClientSetting> {
    return this.http.patch<TrainingClientSetting>(
      ApiUrl.training_client_settings + `${id}/`,
      data
    );
  }

  getTrainingClient(
    id: any,
    params?: HttpParams
  ): Observable<TrainingClientSetting> {
    return this.http.get<TrainingClientSetting>(
      ApiUrl.training_client_settings + `${id}/`
    );
  }

  // duplicateTrainingClient(
  //   id: number,
  //   data?: { [key: string]: any }
  // ): Observable<SurveyClientSetting> {
  //   return this.http.post<SurveyClientSetting>(
  //     ApiUrl.survey_client_settings + `${id}/duplicate/`,
  //     data
  //   );
  // }

  getTrainingClients(params: HttpParams): Observable<DrfResponse<Training>> {
    return this.http.get<DrfResponse<Training>>(ApiUrl.training_client_settings, {
      params: params,
    });
  }

  deleteTrainingClient(id: number, params?: HttpParams): Observable<Training> {
    return this.http.delete<Training>(ApiUrl.training_client_settings + `${id}/`, {params});
  }

  importQuestionOption(
    file: Blob,
    params?: HttpParams
  ): Observable<ImportTask> {
    const data = new FormData();
    data.set('file', file);
    return this.http.post<ImportTask>(
      ApiUrl.training_client_settings + 'import-question-option/',
      data,
      { params }
    );
  }

  deleteQuestion(index: number) {
    this.onDeleteQuestion.next(index);
  }

  //Document
  getDocumentClientSettings(
    params: HttpParams
  ): Observable<DrfResponse<DocumentList>> {
    return this.http.get<DrfResponse<DocumentList>>(
      ApiUrl.document_client_settings,
      {
        params: params,
      }
    );
  }

  getDocumentClientSetting(id: number): Observable<DocumentForm> {
    return this.http.get<DocumentForm>(
      ApiUrl.document_client_settings + `${id}/`
    );
  }

  createDocumentClientSetting(data: FormData): Observable<DocumentForm> {
    return this.http.post<DocumentForm>(ApiUrl.document_client_settings, data);
  }

  editDocumentClientSetting(
    id: number,
    data: FormData
  ): Observable<DocumentForm> {
    return this.http.patch<DocumentForm>(
      ApiUrl.document_client_settings + `${id}/`,
      data
    );
  }

  deleteDocumentClientSetting(id: number): Observable<unknown> {
    return this.http.delete(ApiUrl.document_client_settings + `${id}/`);
  }

  //Upload
  getUploadClientSettings(
    params: HttpParams
  ): Observable<DrfResponse<UploadList>> {
    return this.http.get<DrfResponse<UploadList>>(
      ApiUrl.upload_client_settings,
      {
        params: params,
      }
    );
  }

  getUploadClientSetting(id: number): Observable<UploadForm> {
    return this.http.get<UploadForm>(ApiUrl.upload_client_settings + `${id}/`);
  }

  createUploadClientSetting(data: FormData): Observable<UploadForm> {
    return this.http.post<UploadForm>(ApiUrl.upload_client_settings, data);
  }

  editUploadClientSetting(id: number, data: FormData): Observable<UploadForm> {
    return this.http.patch<UploadForm>(
      ApiUrl.upload_client_settings + `${id}/`,
      data
    );
  }

  deleteUploadClient(id: number): Observable<unknown> {
    return this.http.delete(ApiUrl.upload_client_settings + `${id}/`);
  }

  deleteInformation(
    data: { [key: string]: any },
    params?: HttpParams
  ): Observable<any> {
    return this.http.post<any>(
      ApiUrl.merchandizer_settings + 'bulk-destroy/',
      data,
      {
        params,
      }
    );
  }
}
