<ng-template #modal
    let-c="close"
    let-d="dismiss">
    <div class="modal-header pb-0">
        <div class="modal-title header with-underline">Filter</div>
        <button type="button"
            class="close"
            (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body p-0">
        <ng-content select=".modal-body"></ng-content>
    </div>

    <div class="row col-12">
        <div class="col-6 p-0">
            <button class="btn btn-outline-danger"
                type="button"
                (click)="clearInputs()">
                <svg-icon class="pointer"
                    src="../../../../../assets/images/icons/clear_arrow.svg">
                </svg-icon>
                Clear
            </button>
        </div>
        <div class="col-6 text-right p-0">
            <button class="btn btn-danger"
                type="button"
                (click)="onClickFilter()">
                Confirm
            </button>
        </div>
    </div>
</ng-template>
