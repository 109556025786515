<div class="d-flex nav-bar"
    (mouseleave)="isShow = false">
  <div class="menu-icon d-flex flex-column text-center justify-content-between"
      (mousedown)="isShow = true">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let menu of menus">
        <div *ngIf="menu.display"
            class="icon"
            [ngClass]="{'active': this.active === menu.name}"
            (click)="selectMenu(menu.name)">
          <svg-icon src="../../../assets/images/icons/{{this.active === menu.name ? menu.activeIcon : menu.icon}}">
          </svg-icon>
        </div>
      </ng-container>
    </div>
    <div class="logout py-3">
      <div class="d-flex align-items-center mb-3">
        <div class="position-relative">
          <div class="noti-center noti-img notification ml-1"
              (click)="openNotification()">
            <i class="fa fa-bell fa-2x noti-icon"
                aria-hidden="true"></i>
            <span class="badge-number d-inline-block"
                *ngIf="notificationResult?.unread_count > 0">
              {{notificationResult?.unread_count}}
            </span>
          </div>
          <div *ngIf="showNotificationToggle"
              class="width-box">
            <div class="arrow"></div>
            <div class="container custom-container "></div>
            <div class="row justify-content-between align-items-center px-2 w-title">
              <h3 class="m-1 headerText-custom">Notification</h3>
              <span (click)="readAll()" class="pointer">make all read</span>
            </div>
            <div class="scroll row p-0">
              <div *ngIf="notificationResult.results.length === 0"
                  class="row mx-auto px-1 pt-1 border-bottom-custom col-12 d-flex justify-content-center">
                <p class="pt-3">NO-NOTIFICATION</p>
              </div>
              <div class="pointer border-bottom-custom col-12"
                  *ngFor="let notification of notificationResult.results"
                  (click)="goToNotification(notification)"
                  [ngClass]="{'bg-unread': !notification.read}">
                <div class="flex-column pt-1 custom-box-detail">
                  <div class="d-flex body-01 custom-box-detail">{{ notification?.title_en }}
                  </div>
                  <div class="d-flex text-time custom-box-detail">{{ notification?.body_en }}
                  </div>
                  <div class="d-flex flex-row justify-content-between mt-2 custom-box-detail">
                    <div class="text-time">{{ notification.created_at | date: "dd MMM yyyy" }}</div>
                    <div class="text-time">{{ notification.created_at | date: "h:mm a" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg-icon src="../../../assets/images/icons/logout.svg"
          class="pointer"
          (click)="logoutModal.open()"></svg-icon>
      <app-confirm-modal #logoutModal
          message="Are you sure you want to logout?"
          (confirm)="logout()">
      </app-confirm-modal>
      <div class="profile pointer mt-3"
          (click)="active = null">
        {{ name | uppercase }}
      </div>
    </div>
  </div>
  <div class="sub-menu d-none d-lg-flex"
      [ngClass]="{'show-sub-menu': isShow}">
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="my-5">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #elseTemplate>
  <ng-container *ngFor="let subMenu of getSubMenus">
    <ng-select class="mr-3 mt-2"
        *ngIf="subMenu.type === 'dropdown'"
        [items]="projects"
        bindLabel="name"
        bindValue="id"
        [title]="selectedMenu"
        [(ngModel)]="project"
        [clearable]="false"
        (change)="onProjectChange()">
        <ng-template ng-option-tmp let-item="item">
          <span class="ng-option-label" [attr.title]="item.name">{{ item.name }}</span>
        </ng-template>
    </ng-select>
    <a *ngIf="!subMenu.type"
        class="link"
        [routerLink]="[subMenu.path]"
        [ngClass]="{'active': subMenu.path?.split('/')[1] === path}"
        (click)="isShow = false" [style.display]="subMenu.visible ? 'block' : 'none'">
        <span *ngIf="subMenu.visible === true">{{subMenu.name}}</span>
    </a>
  </ng-container>
</ng-template>
