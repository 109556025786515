import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
} from '@angular/core';
import {
  NgbInputDatepicker,
  NgbDate,
  NgbCalendar,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import {
  ExtendedNgbDateParserFormatter,
  ExtendedNgbDateAdapter,
} from '../../dateparser';

interface DateRange {
  from: NgbDate;
  to: NgbDate;
}

export interface DateRangeString {
  from: string;
  to: string;
}

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnChanges, OnInit {
  @ViewChild('date') dateRange: NgbInputDatepicker;
  @Input() placeholder = 'All';
  @Input() today = false;
  @Input() canShowAll = true;
  @Input() canShowToday = true;
  @Input() maxWidth = false;
  @Input() minDate: NgbDateStruct = null;
  @Input() maxDate: NgbDateStruct = null;
  @Output() dateSelect = new EventEmitter<DateRangeString>();

  @Input() calender;
  @Input() isShowAll = true;
  hoveredDate: NgbDate | null = null;
  @Input() selectedDateRange: DateRange;
  range: DateRange = {
    from: null,
    to: null,
  };

  constructor(
    private calendar: NgbCalendar,
    private formatter: ExtendedNgbDateParserFormatter,
    public ngbFormat: ExtendedNgbDateAdapter
  ) {}

  ngOnChanges(): void {
    if (this.today) {
      this.onToday();
    }
  }
  ngOnInit(): void {
    if (this.selectedDateRange) {
      this.range = this.selectedDateRange;
    }
  }

  get dateString(): string {
    if (!this.range.from && !this.range.to) {
      return '';
    }

    return `${this.formatter.format(this.range.from)} - ${
      this.formatter.format(this.range.to) || ''
    }`;
  }

  dateFormat(date: NgbDate): string {
    return this.ngbFormat.toModel(date);
  }

  get dateRangeString(): DateRangeString {
    return {
      from: this.dateFormat(this.range.from),
      to: this.dateFormat(this.range.to),
    };
  }

  onDateSelection(date: NgbDate): void {
    if (!this.range.from && !this.range.to) {
      this.range.from = date;
    } else if (
      this.range.from &&
      !this.range.to &&
      date.after(this.range.from)
    ) {
      this.range.to = date;
      this.dateRange.close();
      this.isShowAll = false;
      this.dateSelect.emit(this.dateRangeString);
    } else if (
      this.range.from &&
      !this.range.to &&
      date.equals(this.range.from)
    ) {
      this.range.to = date;
      this.dateRange.close();
      this.isShowAll = false;
      this.dateSelect.emit(this.dateRangeString);
    } else {
      this.range.to = null;
      this.range.from = date;
    }
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.range.from &&
      !this.range.to &&
      this.hoveredDate &&
      date.after(this.range.from) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return (
      this.range.to && date.after(this.range.from) && date.before(this.range.to)
    );
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.range.from) ||
      (this.range.to && date.equals(this.range.to)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onToday(): void {
    this.isShowAll = false;
    this.range.from = this.calendar.getToday();
    this.range.to = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    this.dateSelect.emit(this.dateRangeString);
  }

  onClear(): void {
    this.isShowAll = true;
    this.dateSelect.emit(null);
  }
}
