<ng-template #verifyModal>
  <div class="modal-body text-center h5 mt-3" [formGroup]="form">
    <p class="mb-2">Please enter password</p>
    <input type="password" class="form-control" formControlName="password" appValidationForm (keyup.enter)="onConfirm()">
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger" (click)="onConfirm()" [ladda]="loading">Confirm</button>
  </div>
</ng-template>
