import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { SweetAlertService } from '../../../../shared/services/sweet-alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-verify-password-modal',
  templateUrl: './verify-password-modal.component.html',
  styleUrls: ['./verify-password-modal.component.scss'],
})
export class VerifyPasswordModalComponent {
  loading = false;
  form: FormGroup;

  @Output() verified = new EventEmitter<void>();
  @ViewChild('verifyModal') verifyModal: ElementRef;

  constructor(
    private modal: NgbModal,
    private authenticationService: AuthenticationService,
    private swal: SweetAlertService,
    private fb: FormBuilder
  ) {}

  onConfirm(): void {
    this.loading = true;

    this.authenticationService
      .verifyPassword(this.form.get('password').value)
      .subscribe(
        () => {
          this.loading = false;
          this.verified.emit();
          this.close();
        },
        (error) => {
          this.loading = false;
          this.swal.toast({
            type: 'error',
            error: error.error.detail || error.error,
          });

          if (error.status === HttpStatusCode.Forbidden) {
            this.close();
            this.authenticationService.logout();
            location.reload();
          }
        }
      );
  }

  open(): void {
    this.form = this.fb.group({
      password: ['', Validators.required],
    });
    this.modal.open(this.verifyModal, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  close(): void {
    this.modal.dismissAll();
  }
}
