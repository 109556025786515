<div *ngIf="!calender" class="d-flex gap-10">
  <button class="btn" *ngIf="canShowAll" [ngClass]="{'btn-danger': isShowAll, 'btn-outline-danger': !isShowAll}"
    (click)="onClear()">
    All
  </button>
  <button class="btn btn-outline-danger" *ngIf="canShowToday" (click)="onToday()">Today</button>
  <div [class]="maxWidth ? 'input-group date-range max-width-initial' : 'input-group date-range'">
    <input type="text" class="form-control" placeholder="Select date" ngbDatepicker #date="ngbDatepicker"
      [minDate]="minDate" [maxDate]="maxDate" [firstDayOfWeek]="7" (dateSelect)="onDateSelection($event)" 
      [dayTemplate]="dateTemplate" [displayMonths]="2" (click)="date.toggle()" [value]="isShowAll ? placeholder : dateString" 
      tabindex="-1" [autoClose]="'range'" [startDate]="range.from" readonly>
    <div class="input-group-append">
      <div class="input-group-text" (click)="date.toggle()">
        <svg-icon src="../../../../../assets/images/icons/arrow_down.svg"></svg-icon>
      </div>
    </div>
  </div>

  <ng-template #dateTemplate let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null">{{ date.day }}</span>
  </ng-template>
</div>

<div *ngIf="calender" class="d-flex gap-10">
  <div>
    <div [class]="maxWidth ? 'input-group date-range max-width-initial' : 'input-group date-range'">
      <input type="text" class="form-control" placeholder="Select date" ngbDatepicker #date="ngbDatepicker"
        [minDate]="minDate" [maxDate]="maxDate" [firstDayOfWeek]="7" (dateSelect)="onDateSelection($event)" 
        [dayTemplate]="dateTemplate" [displayMonths]="1" (click)="date.toggle()" [value]="isShowAll ? placeholder : dateString" 
        tabindex="-1" [autoClose]="'range'" [startDate]="range.from" readonly>
      <div class="input-group-append">
        <div class="input-group-text" (click)="date.toggle()">
          <svg-icon src="../../../../../assets/images/icons/arrow_down.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
  <ng-template #dateTemplate let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null">{{ date.day }}</span>
  </ng-template>
</div>