import { environment } from '../../../environments/environment';

const BaseUrl = environment.baseUrl + '/backend/api';

export const ApiUrl = {
  login: BaseUrl + '/token-auth/',
  token_refresh: BaseUrl + '/token-refresh/',
  token_blacklist: BaseUrl + '/token-blacklist/',
  verify_password: BaseUrl + '/verify-password/',
  change_password: BaseUrl + '/change-password/',
  reset_password: BaseUrl + '/reset-password/',
  users: BaseUrl + '/users/',
  leave_requests: BaseUrl + '/leave-requests/',
  claim_requests: BaseUrl + '/claims/',
  claims_export:BaseUrl + '/claims/file_download/',
  leave_quotas: BaseUrl + '/leave-quotas/',
  leave_type_settings: BaseUrl + '/leave-type-settings/',
  update_total: BaseUrl + '/leave-quotas/update-total/',
  check_in: BaseUrl + '/activities/',
  clients: BaseUrl + '/clients/',
  employee: BaseUrl + '/employees/',
  projects: BaseUrl + '/projects/',
  dropdowns: BaseUrl + '/dropdown/',
  over_times:BaseUrl + '/over-times/',
  over_time_export: BaseUrl + '/over-times/dashboard/',
  rosters: BaseUrl + '/rosters/',
  roster_plans: BaseUrl + '/rosters/roster-plans/',
  workplaces: BaseUrl + '/workplaces/',
  managers: BaseUrl + '/managers/',
  edit_shifts: BaseUrl + '/edit-shifts/',
  leave_types: BaseUrl + '/leave-types/',
  adjust_requests: BaseUrl + '/adjust-requests/',
  client_leave_type_settings: BaseUrl + '/client-leave-type-settings/',
  project_leave_type_settings: BaseUrl + '/project-leave-type-settings/',
  pin_point_types: BaseUrl + '/pin-point-types/',
  track_routes: BaseUrl + '/track-routes/',
  pin_points: BaseUrl + '/pin-points/',
  ot_requests: BaseUrl + '/ot-requests/',
  working_hours: BaseUrl + '/working-hours/',
  ot_rules: BaseUrl + '/ot-rules/',
  ot_criteria : BaseUrl + '/ot-criteria/',
  ot_criteria_setting : BaseUrl + '/ot-criteria-setting/',
  shops: BaseUrl + '/shops/',
  shop_products: BaseUrl + '/shop-products/',
  shop_images: BaseUrl + '/shop-images/',
  products: BaseUrl + '/products/',
  merchandizer_settings: BaseUrl + '/merchandizer-settings/',
  no_status: BaseUrl + '/no-status/',
  employee_projects: BaseUrl + '/employee-projects/',
  additional_types: BaseUrl + '/additional-types/',
  business_calendars: BaseUrl + '/business-calendars/',
  merchandizer: BaseUrl + '/merchandizers/',
  price_tracking: BaseUrl + '/price-tracking/',
  branches: BaseUrl + '/branches/',
  osa_oos: BaseUrl + '/osa-oos/',
  sku: BaseUrl + '/sku/',
  sale_targets: BaseUrl + '/sale-targets/',
  sale_reports: BaseUrl + '/sale-reports/',
  upload_attachments: BaseUrl + '/upload-attachments/',
  monthly_day_offs: BaseUrl + '/monthly-day-offs/',
  survey_project_settings: BaseUrl + '/survey-project-settings/',
  survey_client_settings: BaseUrl + '/survey-client-settings/',
  survey_export: BaseUrl +'/survey/export/',
  training_export: BaseUrl +'/training/export/',
  training_client_settings: BaseUrl + '/training-client-settings/',
  background_tasks: BaseUrl + '/background-tasks/',
  project_summaries: BaseUrl + '/project-summaries/',
  document_client_settings: BaseUrl + '/document-client-settings/',
  reports: BaseUrl + '/reports/',
  bi_reports: BaseUrl + '/bi-reports/',
  document_project_settings: BaseUrl + '/document-project-settings/',
  upload_project_settings: BaseUrl + '/upload-project-settings/',
  upload_client_settings: BaseUrl + '/upload-client-settings/',
  user_uploads: BaseUrl + '/user-uploads/',
  todo_tasks: BaseUrl + '/todo-tasks/',
  todo_routes: BaseUrl + '/todo-routes/',
  event_stocks: BaseUrl + '/event-stocks/',
  event_stock_logs: BaseUrl + '/event-stock-logs/',
  project_dashboard: BaseUrl + '/project-dashboard/',
  project_overall_dashboard: BaseUrl + '/project-overall-dashboard/',
  custom_report_templates: BaseUrl + '/custom-report-templates/',
  notifications: BaseUrl + '/notifications/',
  surveys: BaseUrl + '/surveys/',
  adecco_business_calendar: BaseUrl + '/adecco-business-calendar/',
  sale_target_periods: BaseUrl + '/sale-target-periods/',
  sku_periods: BaseUrl + '/sku-periods/',
  price_tracking_periods: BaseUrl + '/price-tracking-periods/',
  osa_oos_periods: BaseUrl + '/osa-oos-periods/',
  merchandizer_products: BaseUrl + '/merchandizer-products/',
  training_project_settings: BaseUrl + '/training-project-settings/',
  trainings: BaseUrl + '/trainings/',
};
