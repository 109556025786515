import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './core/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ManagementsGuard } from './core/guards/managements.guard';
import { ReportMenuComponent } from './modules/project-management/pages/report-menu/report-menu.component';
import { TrainingGuard } from './core/guards/training.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'ot-dashboard',
        loadChildren: () =>
          import('./modules/ot-dashboard/ot-dashboard.module').then(
            (m) => m.OtDashboardModule
          ),
      },
      {
        path: 'client',
        loadChildren: () =>
          import('./modules/client-management/client-management.module').then(
            (m) => m.ClientManagementModule
          ),
        canActivate: [ManagementsGuard],
      },
      {
        path: 'employee',
        loadChildren: () =>
          import('./modules/employee/employee.module').then(
            (m) => m.EmployeeModule
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./modules/project-management/project-management.module').then(
            (m) => m.ProjectManagementModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [ManagementsGuard],
      },
      {
        path: 'leave-request',
        loadChildren: () =>
          import('./modules/leave-request/leave-request.module').then(
            (m) => m.LeaveRequestModule
          ),
      },
      //added claims routing
      {
        path: 'claims',
        loadChildren: () =>
          import('./modules/claims/claims.module').then(
            (m) => m.ClaimsModule
          ),
      },
      {
        path: 'ot-request',
        loadChildren: () =>
          import('./modules/ot-request/ot-request.module').then(
            (m) => m.OtRequestModule
          ),
      },
      {
        path: 'check-in',
        loadChildren: () =>
          import('./modules/check-in/check-in.module').then(
            (m) => m.CheckInModule
          ),
      },
      {
        path: 'workplace',
        loadChildren: () =>
          import('./modules/workplace/workplace.module').then(
            (m) => m.WorkplaceModule
          ),
      },
      {
        path: 'roster-plan',
        loadChildren: () =>
          import('./modules/roster-plan/roster-plan.module').then(
            (m) => m.RosterPlanModule
          ),
      },
      {
        path: 'route',
        loadChildren: () =>
          import('./modules/route/route.module').then((m) => m.RouteModule),
      },
      {
        path: 'merchandizer',
        loadChildren: () =>
          import('./modules/merchandizer/merchandizer.module').then(
            (m) => m.MerchandizerModule
          ),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('./modules/survey/survey.module').then((m) => m.SurveyModule),
      },
      //adding training
      {
        path: 'training',
        canActivate: [TrainingGuard],
        loadChildren: () =>
          import('./modules/training/training.module').then((m) => m.TrainingModule),   
      }, 
      {
        path: 'manage-user',
        loadChildren: () =>
          import('./modules/manage-user/manage-user.module').then(
            (m) => m.ManageUserModule
          ),
        canActivate: [ManagementsGuard],
      },
      {
        path: 'manage-branch',
        loadChildren: () =>
          import('./modules/manage-branch/manage-branch.module').then(
            (m) => m.ManageBranchModule
          ),
        canActivate: [ManagementsGuard],
      },
      {
        path: 'adecco-calendar',
        loadChildren: () =>
          import('./modules/adecco-calendar/adecco-calendar.module').then(
            (m) => m.AdeccoCalendarModule
          ),
      },
      {
        path: 'upload',
        loadChildren: () =>
          import('./modules/upload-document/upload-document.module').then(
            (m) => m.UploadDocumentModule
          ),
      },
      {
        path: 'project-dashboard',
        loadChildren: () =>
          import('./modules/project-dashboard/project-dashboard.module').then(
            (m) => m.ProjectDashboardModule
          ),
      },
      {
        path: 'todo',
        loadChildren: () =>
          import('./modules/todo/todo.module').then((m) => m.TodoModule),
      },
      {
        path: 'event',
        loadChildren: () =>
          import('./modules/event-stock/event-stock.module').then(
            (m) => m.EventStockModule
          ),
      },
    ],
  },
  {
    path: 'qr-code/:workplaceId',
    loadChildren: () =>
      import('./modules/qr-code/qr-code.module').then((m) => m.QrCodeModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
