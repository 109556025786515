<ng-template #progressbarModal let-modal>
  <div class="modal-header py-0">
    <button type="button" class="close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h5 class="mb-4">{{ status === 'PENDING' ? 'PENDING' :status }}</h5>
    <ng-container *ngIf="status !== 'FAILURE';">
      <ngb-progressbar
        [animated]="true"
        [striped]="true"
        type="info"
        value="1"
        max="1">
      </ngb-progressbar>
    </ng-container>
  </div>
</ng-template>