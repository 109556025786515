<div class="d-flex gap-10">
  <label [class]="'switch ' + color">
    <ng-container *ngIf="reverse; else elseTemplate">
      <input type="checkbox" [ngModel]="!value" (change)="onChange($event.target.checked)" [disabled]="readonly">
    </ng-container>
    <ng-template #elseTemplate>
      <input type="checkbox" [(ngModel)]="value" (change)="onChange()" [disabled]="readonly">
    </ng-template>

    <span class="slider round" [ngClass]="{'readonly': readonly}"></span>
  </label>

  <div class="pointer" [ngClass]="{'text-muted': reverse ? value : !value}" (click)="onChange(value)">{{ label }}</div>
</div>