import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('map') map: GoogleMap;
  @Input() clickable = false;
  @Input() options: google.maps.MapOptions = {
    keyboardShortcuts: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    streetViewControl: true,
    streetView: null,
  };

  /**
   * If single `position` element provided, marker will be shown (`isDirection` = false)
   * Otherwise, direction will be shown instead of marker (`isDirection` = true)
   */
  @Input() position: google.maps.LatLngLiteral[];

  @Input() expectedRoute: google.maps.LatLngLiteral[];

  @Input() searchLatLng: google.maps.LatLngLiteral;
  @Output() changePosition = new EventEmitter();
  @Output() mapClick = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() distance = new EventEmitter<number>();

  center: google.maps.LatLngLiteral = undefined;

  route = new google.maps.DirectionsService();

  markerOptions: google.maps.MarkerOptions = {
    animation: google.maps.Animation.DROP,
  };

  constructor() {}

  ngOnInit(): void {
    this.setCenter();

    const initialPanorama = new google.maps.StreetViewPanorama(
      document.getElementById('pano') as HTMLElement,
      {
        position: this.position[0],
        pov: {
          heading: 34,
          pitch: 10,
        },
      }
    );
    this.options.streetView = initialPanorama;
  }

  getDirectionRequest(
    positions: google.maps.LatLngLiteral[]
  ): google.maps.DirectionsRequest {
    return {
      // first position
      origin: positions[0],
      // last position
      destination: positions[positions.length - 1],
      // other positions between first and last
      waypoints:
        positions.length > 2
          ? positions.slice(1, positions.length - 1).map((obj) => ({
              location: new google.maps.LatLng(obj.lat, obj.lng),
            }))
          : [],
      travelMode: google.maps.TravelMode.DRIVING,
    };
  }

  ngAfterViewInit(): void {
    if (this.expectedRoute) {
      this.route.route(
        this.getDirectionRequest(this.expectedRoute),
        (result) => {
          const direction = new google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: 'red',
              strokeOpacity: 0.5,
              strokeWeight: 6,
            },
          });
          direction.setDirections(result);
          direction.setMap(this.map.googleMap);
        }
      );
    }

    if (this.isDirection) {
      this.route.route(this.getDirectionRequest(this.position), (result) => {
        let distance = 0;
        result.routes.forEach((route) => {
          distance = route.legs
            .map((leg) => leg.distance.value)
            .reduce((value, next) => value + next, 0);
        });
        this.distance.emit(distance);

        const direction = new google.maps.DirectionsRenderer();
        direction.setDirections(result);
        direction.setMap(this.map.googleMap);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // center is already ignored when using direction
    if (this.isDirection) {
      return;
    }

    if (!this.clickable) {
      this.setCenter();
      return;
    }

    const position = changes.position;
    if (!position.previousValue && position.currentValue) {
      this.center = {
        lat: changes.position[0]?.currentValue?.lat || 13.7563,
        lng: changes.position[0]?.currentValue?.lng || 100.5018,
      };
    }

    if (changes.searchLatLng?.previousValue) {
      this.center = this.searchLatLng;
    }
  }

  setCenter(): void {
    this.center = this.position[0];
  }

  onMapClicked(event: google.maps.MapMouseEvent): void {
    const latLng = event.latLng.toJSON();

    this.position = [latLng];
    this.mapClick.emit(latLng);
  }

  get isDirection(): boolean {
    return this.position?.length > 1;
  }
}
