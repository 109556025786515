import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DrfResponse } from '../../shared/models/drf-response.models';
import { ApiUrl } from '../http/api.constant';
import {
  FieldMarketing,
  HRSolutions,
  Project,
  ProjectAllowance,
  ProjectDetail,
  ProjectFeatureSetting,
} from '../../shared/models/project.model';
import { Schedule } from 'src/app/shared/models/roster-plan.model';
import { LeaveType } from 'src/app/shared/models/leave.mode';
import { Dropdown } from 'src/app/shared/models/dropdown.model';
import { MerchandizerMenu } from 'src/app/shared/models/merchandizer-information.model';
import { ImportTask } from 'src/app/shared/models/background-task.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  querySubject = new Subject<{ [key: string]: any }>();
  projectSubject: BehaviorSubject<ProjectDetail>;
  projectAllowanceSubject: BehaviorSubject<HRSolutions & FieldMarketing>;

  constructor(private http: ApiService) {
    this.projectSubject = new BehaviorSubject<ProjectDetail>(null);
    this.projectAllowanceSubject = new BehaviorSubject<
      HRSolutions & FieldMarketing
    >(null);
  }

  postImportProject(formData: FormData): Observable<ImportTask> {
    return this.http.post<ImportTask>(ApiUrl.projects + 'import/', formData);
  }

  getMerchandizerFilterMenu(
    menus: MerchandizerMenu[],
    allowance?: HRSolutions & FieldMarketing
  ): MerchandizerMenu[] {
    return menus.filter((element: MerchandizerMenu) => {
      if (!element.property) return true;

      return allowance[element.property];
    });
  }

  getProjectAllowanceList(): Observable<ProjectAllowance[]> {
    return this.http.get<ProjectAllowance[]>(ApiUrl.projects + 'allowance/');
  }

  getFeatureSetting(
    id: number,
    params?: HttpParams
  ): Observable<ProjectFeatureSetting> {
    return this.http.get<ProjectFeatureSetting>(ApiUrl.projects + `${id}/`, {
      params,
    });
  }

  patchFeatureSetting(
    id: number,
    data: ProjectFeatureSetting,
    params?: HttpParams
  ): Observable<ProjectFeatureSetting> {
    return this.http.patch<ProjectFeatureSetting>(
      ApiUrl.projects + `${id}/`,
      data,
      { params }
    );
  }

  patchSaleReportUnit(id: number, data: FormData): Observable<ProjectDetail> {
    return this.http.patch<ProjectDetail>(ApiUrl.projects + `${id}/`, data);
  }

  getLeaveTypeSettings(params?: {
    client?: number;
    project?: number;
  }): Observable<DrfResponse<LeaveType>> {
    let httpParams = new HttpParams();
    if (params?.client && params?.project) {
      httpParams = httpParams.append('project', params.project || '');
    } else if (params?.client) {
      httpParams = httpParams.append('client', params.client);
    } else if (params?.project) {
      httpParams = httpParams.append('project', params.project || '');
    }
    return this.http.get<DrfResponse<LeaveType>>(ApiUrl.leave_type_settings, {
      params: httpParams,
    });
  }

  getProject(params?: HttpParams): Observable<DrfResponse<Project>> {
    return this.http.get<DrfResponse<Project>>(ApiUrl.projects, { params });
  }

  getProjectDetail(id: number, params?: HttpParams): Observable<ProjectDetail> {
    return this.http.get<ProjectDetail>(ApiUrl.projects + `${id}/`, { params });
  }

  createProject(data: { [key: string]: any }): Observable<string> {
    return this.http.post(ApiUrl.projects, data);
  }

  editProject(
    id: number,
    data: { [key: string]: any }
  ): Observable<ProjectDetail> {
    return this.http.patch<ProjectDetail>(ApiUrl.projects + `${id}/`, data);
  }

  getWorkingHours(id: number, params?: HttpParams): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(ApiUrl.projects + `${id}/working-hours/`, {
      params,
    });
  }

  patchLeaveType(id: number, leaveType: LeaveType): Observable<LeaveType> {
    return this.http.patch(ApiUrl.leave_type_settings + `${id}/`, leaveType);
  }

  dropdownLoa(id: number, params?: HttpParams): Observable<Dropdown[]> {
    return this.http.get<Dropdown[]>(
      ApiUrl.projects + `${id}/` + 'loa-users/',
      {
        params,
      }
    );
  }

  importProjectDropdown(
    file: Blob,
    params?: HttpParams
  ): Observable<ImportTask> {
    const data = new FormData();
    data.set('file', file);
    return this.http.post<ImportTask>(
      ApiUrl.projects + 'dropdown-excel/import/',
      data,
      { params }
    );
  }

  deleteProject(id: number) {
    return this.http.delete(ApiUrl.projects + `${id}/`);
  }
}
