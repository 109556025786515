import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from '../../../environments/environment';
import { VerifyPasswordModalComponent } from './components/verify-password-modal/verify-password-modal.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = true;

  @ViewChild('verifyModal') verifyModal: VerifyPasswordModalComponent;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {}

  ngOnInit(): void {
    this.setUpIdle();
    this.refreshToken();
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem('idled') === 'true') {
      this.verifyModal.open();
    }
  }

  setUpIdle(): void {
    this.idle.setIdle(environment.sessionSoftTimeout);
    this.idle.setTimeout(
      environment.sessionHardTimeout - environment.sessionSoftTimeout
    );
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (!localStorage.getItem('idled')) {
        localStorage.setItem('idled', 'true');
        this.verifyModal.open();
      }
    });
    this.idle.onTimeout.subscribe(() => {
      this.verifyModal.close();
      this.logout();
    });

    this.keepalive.interval(environment.sessionHardTimeout / 2);
    this.keepalive.onPing.subscribe(() => {
      this.refreshToken();
    });

    this.idle.watch();
  }

  verified(): void {
    localStorage.removeItem('idled');
  }

  refreshToken(): void {
    this.authenticationService.refreshToken().subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.logout();
      }
    );
  }

  logout(): void {
    this.authenticationService.logout();
    location.reload();
  }

  ngOnDestroy(): void {
    this.idle.stop();
    this.keepalive.stop();
  }
}
