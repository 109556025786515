import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  providers: [NgbActiveModal],
})
export class FilterModalComponent implements OnInit {
  @ViewChild('modal') contentModal: ElementRef;
  @Output() confirm = new EventEmitter<any>();
  @Input() filter: Record<string, any>;

  constructor(private modal: NgbModal) {}

   ngOnInit(): void { var init; }

  open() {
    Object.keys(this.filter).forEach((key) => (this.filter[key] = ''));
    this.modal.open(this.contentModal, {
      centered: true,
      backdrop: 'static',
    });
  }
  clearInputs() {
    if (typeof this.filter === 'object' && this.filter !== null) {
      Object.keys(this.filter).forEach((key) => (this.filter[key] = ''));
    }
    this.onClickFilter();
  }

  close() {
    this.modal.dismissAll();
  }

  onClickFilter() {
    this.confirm.emit(this.filter);
  }
}
