<ngx-file-drop dropZoneLabel="Browse or Drop file here" *ngIf="!training"
    [accept]="accept"
    (onFileDrop)="dropped($event)">
  <ng-template ngx-file-drop-content-tmp let-openFile="openFileSelector">
    <div class="text-center pointer" (click)="openFile()">
      <img src="../../../../assets/images/icons/file-folder.svg" alt="">
      <div class="upload-label">
        <svg-icon src="../../../../assets/images/icons/upload.svg" class="mr-1"></svg-icon>
        Browse or drop file here
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
<ngx-file-drop  *ngIf="training" dropZoneLabel="Browse or Drop file here"
    [accept]="accept"
    (onFileDrop)="dropped($event)" [multiple]="false">
  <ng-template ngx-file-drop-content-tmp let-openFile="openFileSelector">
    <div class="text-center pointer" (click)="openFile()">
      <img src="../../../../assets/images/icons/file-folder.svg" alt="">
      <div class="upload-label">
        <svg-icon src="../../../../assets/images/icons/upload.svg" class="mr-1"></svg-icon>
        Browse or drop file here
      </div>
    </div>
  </ng-template>
</ngx-file-drop>