import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

function unbleach(data: any): any {
  if (Array.isArray(data)) {
    return data.map((item) => unbleach(item));
  }

  if (data?.constructor === Object) {
    return Object.entries(data)
      .map(([key, value]) => [key, unbleach(value)])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  if (typeof data === 'string') {
    data = data
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&');
  }

  return data;
}

@Injectable()
export class BleachInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        return event.clone({ body: unbleach(event.body) });
      })
    );
  }
}
