import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrl } from '../http/api.constant';
import { LoginResponse, TokenResponse } from '../../shared/models/user.models';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoginResponse>;
  public currentUser: Observable<LoginResponse>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginResponse {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(ApiUrl.login, { username, password, website: true })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  refreshToken(): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(ApiUrl.token_refresh, {
        refresh: this.currentUserValue.refresh,
      })
      .pipe(
        map((res) => {
          const user = this.currentUserValue;
          user.access = res.access;
          user.refresh = res.refresh;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  verifyPassword(password: string): Observable<void> {
    return this.http.post<void>(ApiUrl.verify_password, { password });
  }

  logout(): void {
    const access = this.currentUserValue?.access;
    const refresh = this.currentUserValue?.refresh;

    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);

    if (!refresh) {
      return;
    }

    // blacklist current access/refresh token
    this.http
      .post<void>(ApiUrl.token_blacklist, {
        access,
        refresh,
      })
      .subscribe();
  }

  setExpiredPasswordCurrentUser(value: boolean): void {
    const user = this.currentUserValue as LoginResponse;
    user.user.expired_password = value;
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  setDefaultPasswordChanged(value: boolean): void {
    const user = this.currentUserValue as LoginResponse;
    user.user.default_password_changed = value;
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
}
