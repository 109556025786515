import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { SweetAlertService } from '../../services/sweet-alert.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Input() accept: string;
  @Output() getFile = new EventEmitter<File[]>();
  @Input() training:boolean;

  constructor(private swal: SweetAlertService) {}

  dropped(files: NgxFileDropEntry[]): void {
    const emittedFiles: File[] = [];

    if (files.length <= 10) {
      for (const droppedFile of files) {
        if(this.training){
          if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name) ) {
           
           const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
           fileEntry.file((file: File) => {
            //  console.log(droppedFile.relativePath, file);
             if (this.isFileSizeAllowed(file.size)) {
             emittedFiles.push(file);
             }else{
               this.swal.toast({ type: 'error', msg: "please upload file size upto 10MB " });
               // alert("error");
             }
           });
         }else{
           this.swal.toast({ type: 'error', msg: "please upload pdf, ppt, pptx, doc, docx files only" });
           // alert("error");
         }
       }
        if (droppedFile.fileEntry.isFile && !this.training) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            emittedFiles.push(file);
          });
        }
      }
      this.getFile.emit(emittedFiles);
    }
  }
  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = [".pdf", ".ppt", ".doc",".docx",".pptx"];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }
  isFileSizeAllowed(size){
    let isFileSizeAllowed = false;
      if (size < 10000000) {
        isFileSizeAllowed = true;
      }
      return isFileSizeAllowed;
  }
}
