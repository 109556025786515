import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiUrl } from 'src/app/core/http/api.constant';
import { ApiService } from 'src/app/core/http/api.service';
import { DrfResponse as DrfRes } from '../models/drf-response.models';
import {
  Employee,
  EmployeeListItem,
  EmployeeProject,
  EmployeeProjects as EmployeeProjects,
  EmployeeUpdateRequest,
} from '../models/employee.model';
import { ImportTask } from '../models/background-task.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EmployeeApiService {
  querySubject = new Subject<{ [key: string]: any }>();

  constructor(private api: ApiService) {}

  assignProject(
    id: number,
    request: { employee_project: EmployeeProjects },
    params?: HttpParams
  ): Observable<EmployeeProjects> {
    return this.api.post(ApiUrl.employee + `${id}/assign-project/`, request, {
      params,
    });
  }

  getList(params?: HttpParams): Observable<DrfRes<EmployeeListItem>> {
    return this.api.get(ApiUrl.employee_projects, { params });
  }

  getDetail(id: number): Observable<Employee> {
    return this.api.get(ApiUrl.employee + `${id}/`);
  }

  patchEmployeeProject(
    id: number,
    request: Partial<EmployeeUpdateRequest>,
    params?: HttpParams
  ): Observable<EmployeeProject> {
    return this.api.patch(ApiUrl.employee_projects + `${id}/`, request, {
      params,
    });
  }

  deleteEmployee(id: number) {
    return this.api.delete(ApiUrl.employee_projects + `${id}/`);
  }

  importEmployeeProjectDropdown(
    file: Blob,
    params?: HttpParams
  ): Observable<ImportTask> {
    const data = new FormData();
    data.set('file', file);
    return this.api.post<ImportTask>(
      ApiUrl.employee_projects + 'dropdown-excel/import/',
      data,
      { params }
    );
  }

  patchEmployee(
    id: number,
    request: Partial<EmployeeUpdateRequest>
  ): Observable<Employee> {
    return this.api.patch(ApiUrl.employee + `${id}/`, request);
  }

  postEmployee(
    request: EmployeeUpdateRequest,
    params?: HttpParams
  ): Observable<Employee> {
    return this.api.post(ApiUrl.employee, request, {
      params,
    });
  }

  importEmployee(file: Blob): Observable<ImportTask> {
    const data = new FormData();
    data.set('file', file);
    return this.api.post<ImportTask>(ApiUrl.employee + 'import/', data);
  }
}
