import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { VerifyPasswordModalComponent } from './components/verify-password-modal/verify-password-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [MainComponent, VerifyPasswordModalComponent],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    LaddaModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class MainModule {}
