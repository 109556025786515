<ng-template #confirm
    let-modal>
  <div class="modal-header pb-0">
    <div class="modal-title header with-underline">
      {{ title }}
    </div>
    <button type="button"
        class="close"
        (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="isUploadExcel; else elseBlock">
      1. Download template for import file by click
      <a href="javascript:void(0)" (click)="downloadTemplate()">
        here
      </a><br />
      <ng-content></ng-content>
      2. Upload .xlsx file below
      <br /><br />
      <strong>Upload XLSX file</strong>
    </div>

    <ng-template #elseBlock>
      <div *ngIf="isUploadImage; else elsePdfBlock">Upload .JPG/JPEG .PNG files (Up to 10 files)</div>
      <ng-template #elsePdfBlock> Upload .PDF file </ng-template>
    </ng-template>

    <div class="mt-3">
        <app-upload-file (getFile)="onGetFile($event)"
            *ngIf="multiple || files.length === 0"
            [accept]="accept"></app-upload-file>
      <div class="d-flex justify-content-lg-between gap-10"
          *ngFor="let file of files;let i=index; ">
        <div class="filename flex-grow-1">{{ file.name }}</div>
        <div class="remove d-flex"
            (click)="onClickRemove(i)">
          <svg-icon src="../../../../../assets/images/icons/remove.svg"></svg-icon>
          <span class="ml-1">remove</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-danger"
        type="button"
        (click)="modal.dismiss()">
      Cancel
    </button>
    <button class="btn btn-danger"
        type="button"
        (click)="modal.close()">Confirm</button>
  </div>
</ng-template>
