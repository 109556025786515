import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DrfResponse } from 'src/app/shared/models/drf-response.models';
import { NotificationList } from 'src/app/shared/models/notification.model';
import { ApiUrl } from '../http/api.constant';
import { ApiService } from '../http/api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: ApiService, private router: Router) {}

  getAllNotification(): Observable<DrfResponse<NotificationList>> {
    return this.http.get<DrfResponse<NotificationList>>(ApiUrl.notifications);
  }

  updateReadAll(data?: unknown): Observable<unknown> {
    return this.http.post(ApiUrl.notifications + 'read-all/', { data });
  }
}
