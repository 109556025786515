import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appBackLocation]',
})
export class BackLocationDirective {
  @Input() backStep = 1;
  @Input() relativeToSpecific = 'default';
  @Input() locationBack = false;
  typeRelativeTo = { default: this.route, parent: this.route.parent };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  @HostListener('click', ['$event']) handleClickEvent(): void {
    if (this.locationBack) {
      this.location.back();
      return;
    }

    this.router.navigate(
      [Array(this.backStep).fill('..').toString().replace(',', '/')],
      {
        relativeTo: this.typeRelativeTo[this.relativeToSpecific],
      }
    );
  }
}
