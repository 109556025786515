<ng-template #progressModal let-modal>
  <div class="modal-header py-0">
    <button type="button" class="close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h5 class="mb-4">{{ task.status === 'PROGRESS' ? 'IMPORTING' : task.status }}</h5>

    <ng-container *ngIf="task.status !== 'FAILURE'; else errorTemplate">
      <ngb-progressbar
        [animated]="loading"
        [striped]="loading"
        [showValue]="['SUCCESS', 'PROGRESS'].includes(task.status)"
        [type]="progressType"
        [value]="currentRow"
        [max]="totalRow">
      </ngb-progressbar>
    </ng-container>

    <ng-template #errorTemplate>
      <div *ngIf="task.detail" class="text-danger overflow-auto" style="max-height: 300px;">
        <ul class="list-group">
          <li *ngFor="let err of $any(task.detail)" class="list-group-item">
            {{ err }}
          </li>
        </ul>
      </div>
    </ng-template>

    <div class="mt-3" *ngIf="downloadResultEnabled && task.status === 'SUCCESS'">
      <button type="button"
          class="btn btn-danger"
          [ladda]="loadingResult"
          (click)="downloadResult()">
        Download Password CSV
    </button>
    </div>
  </div>
</ng-template>
