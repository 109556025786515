import { DrfResponse } from './../../shared/models/drf-response.models';
import {
  CheckIn,
  CheckInDetail,
  NoStatus,
} from './../../shared/models/check-in.model';
import { HttpParams } from '@angular/common/http';
import { ApiUrl } from './../http/api.constant';
import { Observable, Subject } from 'rxjs';
import { ApiService } from './../http/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckInService {
  checkInSubject = new Subject();
  querySubject = new Subject<{ [key: string]: any }>();
  Url: any;

  constructor(private http: ApiService) {}

  getCheckInList(params?: HttpParams): Observable<DrfResponse<CheckIn>> {
    return this.http.get<DrfResponse<CheckIn>>(ApiUrl.check_in + 'dashboard/', {
      params,
    });
  }

  getCheckInDetail(id: number, params?: HttpParams): Observable<CheckInDetail> {
    return this.http.get<CheckInDetail>(ApiUrl.check_in + `${id}/details/`, {
      params,
    });
  }

  changeAction(id: number, data, params?: HttpParams): Observable<any> {
    return this.http.patch(ApiUrl.check_in + `${id}/actions/`, data, {
      params,
    });
  }

  getNoStatusList(params: HttpParams): Observable<DrfResponse<NoStatus>> {
    return this.http.get<DrfResponse<NoStatus>>(ApiUrl.no_status, { params });
  }

  getNoStatusDetail(params: HttpParams): Observable<CheckInDetail> {
    return this.http.get<CheckInDetail>(ApiUrl.no_status + 'detail/', {
      params,
    });
  }

  createNoStatus(
    data: { [key: string]: any },
    params?: HttpParams
  ): Observable<string> {
    return this.http.post(ApiUrl.check_in, data, { params });
  }

  updateCheckOut(
    data: { [key: string]: any },
    params?: HttpParams
  ): Observable<string> {
    return this.http.patch(ApiUrl.check_in, data, { params });
  }

  updateCheckinout(
    data: { [key: string]: any },
  ): Observable<string> {
    const params = new HttpParams().set('type','check_in_out_update')
    return this.http.post(ApiUrl.check_in + 'dashboard/', data, { params });
  }
  getCheckinoutDateTime(id, type ): Observable<any> {
    const params = new HttpParams().set('type',type).set('checkinout_id',id)
    return this.http.get<DrfResponse<CheckIn>>(ApiUrl.check_in + 'dashboard/', {
      params,
    });
  }
}
