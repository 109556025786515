import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface ElementRefAddList extends ElementRef {
  open: (items?: string[], storage?: { [key: string]: any }) => void;
}

@Component({
  selector: 'app-add-list-modal',
  templateUrl: './add-list-modal.component.html',
  styleUrls: ['./add-list-modal.component.scss'],
})
export class AddListModalComponent {
  @ViewChild('modal', { static: false }) appModal: ElementRef;
  @Input() addLabel: string;
  @Input() title: string;
  @Input() itemName: string;
  @Input() singleElement = false;
  @Output() confirm = new EventEmitter<{
    items: string[];
    storage: { [key: string]: any };
  }>();
  openedModal: NgbModalRef;
  items: any[] = [{ name: '' }];
  storage: { [key: string]: any } = {};

  constructor(private modal: NgbModal) {}

  open(items?: string[], storage?: { [key: string]: any }): void {
    const objArray: { name: string }[] = items?.map((name) => ({ name }));
    this.openedModal = this.modal.open(this.appModal, { centered: true });
    this.storage = storage;
    this.items = objArray || [{ name: '' }];
  }

  addItem(): void {
    this.items.push({ name: '' });
  }

  deleteItem(index: number): void {
    this.items.splice(index, 1);
  }

  onConfirm(): void {
    const nameArray: string[] = this.items.map((obj) => obj.name);
    this.confirm.emit({ items: nameArray, storage: this.storage });
    this.openedModal.close();
  }
}
