<div class="customHeaderContainer mr-1"
    (click)="onSortRequested($event)">
    <div [ngClass]="{'show-header': params.selection}"
        class="customHeaderLabel">{{ params.displayName }}</div>
    <div [ngClass]="{'show-header': params.selection}"
        class="ml-2 vertical-align-center">
        <ng-container *ngIf="!params.column.isSortAscending() && !params.column.isSortDescending()">
            <i class="fa fa-sort"
                aria-hidden="true"></i>
        </ng-container>
        <i *ngIf="params.column.isSortAscending()"
            class="fa fa-sort-alpha-up"
            aria-hidden="true"></i>
        <i *ngIf="params.column.isSortDescending()"
            class="fa fa-sort-alpha-down"
            aria-hidden="true"></i>
    </div>
</div>