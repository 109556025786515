import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-progressbar-modal',
  templateUrl: './progressbar-modal.component.html',
  styleUrls: ['./progressbar-modal.component.scss'],
})
export class ProgressbarModalComponent implements OnInit {
  @ViewChild('progressbarModal') progressbarModal: ElementRef;
  @Input() status: string;

  constructor(private modal: NgbModal) {}

   ngOnInit(): void { var init; }

  open(): void {
    this.modal.open(this.progressbarModal, {
      centered: true,
      backdrop: 'static',
    });
  }

  close(): void {
    this.modal.dismissAll();
  }
}
