<div class="d-flex h-100" *ngIf="!loading">
  <app-navbar></app-navbar>

  <div class="bg-gray flex-grow-1">
    <div class="box">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-loading-screen *ngIf="loading"></app-loading-screen>

<app-verify-password-modal #verifyModal (verified)="verified()"></app-verify-password-modal>
