import { Menu } from 'src/app/shared/models/menu.model';

export const MENU: Menu[] = [
  {
    name: 'Dashboard',
    icon: 'dashboard.svg',
    activeIcon: 'dashboard_active.svg',
    subMenu: [
      {
        name: 'Project Summary',
        path: '/dashboard',
        visible: true, 
      },
      {
        name: 'OT Dashboard',
        path: '/ot-dashboard',
        visible: true, 
      },
    ],
  },
  {
    name: 'Project Menu',
    icon: 'project.svg',
    activeIcon: 'project_active.svg',
    subMenu: [
      {
        name: 'Project',
        type: 'dropdown',
        visible: true, 
      },
      {
        name: 'Dashboard',
        path: '/project-dashboard/hr-solution',
        visible: true, 
      },
      {
        name: 'Reports',
        path: '/report/reports-menu',
        visible: true, 
      },
      {
        name: 'Check-in & Check-out',
        path: '/check-in/all',
        property: 'check_in_check_out',
        visible: true, 
      },
      {
        name: 'Roster Plan',
        path: '/roster-plan/view',
        property: 'roster_plan',
        visible: true, 
      },
      {
        name: 'Leave Request',
        path: '/leave-request/all',
        property: 'leave_request',
        visible: true, 
      },
      {
        name: 'Claims',
        path: '/claims/all',
        property: 'claims',
        visible: true, 
      },
      {
        name: 'OT',
        path: '/ot-request/ot-quota-setting',
        property: 'ot_request',
        visible: true, 
      },
      {
        name: 'Track route & Pinpoint',
        path: '/route/track-route',
        property: 'track_route_pin_point',
        visible: true, 
      },
      {
        name: 'Merchandizer & Sales Report',
        path: '/merchandizer/merchandizer-information',
        property: 'merchandizer',
        visible: true, 
      },
      {
        name: 'Event Stock',
        path: '/event/event-stock',
        property: 'event_stock',
        visible: true, 
      },
      {
        name: 'To-Do List',
        path: '/todo',
        property: 'todo',
        visible: true, 
      },
      {
        name: 'Survey',
        path: '/survey',
        property: 'survey',
        visible: true, 
      },
      //adding training menu item
      {
        name: 'Training',
        path: '/training',
        property: 'training',
        visible: true, 
      },
      {
        name: 'Upload & Document',
        path: '/upload',
        property: 'upload',
        visible: true, 
      },
      {
        name: 'Employee Data',
        path: '/employee',
        visible: true, 
      },
      {
        name: 'Workplace Data',
        path: '/workplace',
        visible: true, 
      },
    ],
  },
  {
    name: 'Ahead Management',
    icon: 'ahead_management.svg',
    activeIcon: 'ahead_management_active.svg',
    subMenu: [
      {
        name: 'Client Management',
        path: '/client',
        visible: true, 
      },
      {
        name: 'Project Management',
        path: '/projects',
        visible: true, 
      },
      {
        name: 'User Management',
        path: '/user',
        visible: true, 
      },
    ],
  },
  {
    name: 'Ahead User',
    icon: 'adecco_user_icon.svg',
    activeIcon: 'adecco_user_icon_active.svg',
    subMenu: [
      {
        name: 'Adecco User Management',
        path: '/manage-user',
        visible: true, 
      },
      {
        name: 'Branch Management',
        path: '/manage-branch',
        visible: true, 
      },
      {
        name: `Adecco's Calendar`,
        path: '/adecco-calendar',
        visible: true, 
      },
    ],
  },
];

export const PROFILE = {
  name: 'Profile',
  subMenu: [
    {
      name: 'Profile',
      path: '/profile',
      visible: true, 
    },
    {
      name: 'Change Password',
      path: '/change-password',
      visible: true, 
    },
  ],
};
