import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-gallery',
  templateUrl: './carousel-gallery.component.html',
  styleUrls: ['./carousel-gallery.component.scss'],
})
export class CarouselGalleryComponent {
  @ViewChild('carousel') carousel: NgbCarousel;
  @Input() gallery = [];
  @Input() activeIndex = 0;
  @Output() closeModal = new EventEmitter();

  constructor() {}

  onSlide(event: NgbSlideEvent): void {
    this.activeIndex = +event.current.replace('slide-index-', '');
  }

  onSelect(index: number): void {
    this.carousel.select('slide-index-' + index);
  }

  onClose(): void {}
}
