import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Dropdown } from '../../models/dropdown.model';
import { ApiUrl } from '../../../core/http/api.constant';
import { ApiService } from '../../../core/http/api.service';
import { SweetAlertService } from '../../services/sweet-alert.service';
import { UploadFile } from '../upload-file-modal/upload-file-modal.component';
import { HttpParams } from '@angular/common/http';
import { ProjectService } from 'src/app/core/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { UploadProgressModalComponent } from '../upload-progress-modal/upload-progress-modal.component';
import { ClientSettingService } from 'src/app/core/services/client-setting.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges {
  @Input() dataLabel: string;
  @Input() addedLabel: string;
  @Input() selected: number[];
  @Input() dataList: Dropdown[];
  @Input() canSearchAdded = false;
  @Input() canCheckProject = false;
  @Input() isUsedLastYear = false;
  @Input() canImport = true;
  @Input() templateUrl: string;
  @Input() templateFileName: string;
  @Input() assignAll = false;
  @Input() featureType: string;
  @Output() selectedChange = new EventEmitter<number[]>();
  @Output() checkedChange = new EventEmitter<number[]>();
  @Output() search = new EventEmitter<string>();
  @Output() status = new EventEmitter<string>();
  @ViewChild('progressModal') progressModal: UploadProgressModalComponent;

  dataSet: Set<Dropdown>;
  addedSet: Set<Dropdown> = new Set([]);
  selectedSet: Set<Dropdown> = new Set([]);
  filter: string;

  clientId: number;
  importTaskId: string;
  isAssigned = false;
  isChecked = false;
  checkedValues: number[] = [];
  showWorkplaceArray: boolean[] = [];
  showSelected: boolean;

  constructor(
    private apiService: ApiService,
    private swal: SweetAlertService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private service: ClientSettingService
  ) {
    const settingTypeIds = this.service.getIdOfSettingType();
    this.clientId = settingTypeIds.client;
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.syncSelectedAndAddedSet();
    if (changes.dataList) {
      this.dataSet = new Set(
        this.selected
          ? this.dataList?.filter((data) => !this.selected.includes(data.value))
          : this.dataList
      );
    }
    if (this.selected && !this.addedSet.size) {
      this.addedSet = new Set(
        this.dataList?.filter((data) => this.selected.includes(data.value))
      );
      this.addedSet.forEach((employee) => {
        this.dataSet.delete(employee);
      });
    }
  }

  syncSelectedAndAddedSet(): void {
    if (!this.selected?.length) {
      this.addedSet = new Set([]);
    }
  }

  filterAddedSet(): Set<Dropdown> {
    if (!this.filter) {
      return this.addedSet;
    }
    return new Set(
      Array.from(this.addedSet).filter((data) =>
        data.label.includes(this.filter)
      )
    );
  }

  hideAssignEmployee(): void {
    const filterAddedSetArray = this.filterAddedSetArray();
    this.selectedSet.forEach((data) => {
      data.selected = false;
    });
    filterAddedSetArray.forEach((data) => {
      data.selected = false;
    });
  }

  markSelectedAll(): void {
    const filterAddedSetArray = this.filterAddedSetArray();
    const condition = (data) =>
      !data.context.has_answered_survey || !data.context.has_answered_upload;

    filterAddedSetArray.filter(condition).forEach((data) => {
      data.selected = true;
    });
  }

  checkContextAnswer(data: Dropdown): boolean {
    const key =
      this.featureType === 'survey'
        ? 'has_answered_survey'
        : 'has_answered_upload';
    return !data.context[key];
  }

  filterAddedSetArray(): Dropdown[] {
    return Array.from(this.filterAddedSet());
  }

  onCheck(checked: boolean) {
    if (checked) {
      this.showSelected = true;
      this.status.emit('Revoke');
    }
  }

  onSelect(selected: Dropdown, isChecked?: boolean): void {
    if (isChecked) {
      this.checkedValues.push(selected.value);
    } else {
      const index = this.checkedValues.indexOf(selected.value);
      if (index > -1) {
        this.checkedValues.splice(index, 1);
      }
    }
    this.checkedChange.emit(this.checkedValues);

    if (this.selectedSet.has(selected)) {
      this.selectedSet.delete(selected);
      return;
    }
    this.selectedSet.add(selected);
  }

  onAdd(): void {
    if (!this.selectedSet.size) {
      return;
    }
    this.selectedSet.forEach((employee) => {
      this.addedSet.add(employee);
      this.dataSet.delete(employee);
    });
    this.addedSet = this.onSort(this.addedSet);
    this.onSelectedChange();
  }

  onRemove(): void {
    if (!this.selectedSet.size) {
      return;
    }
    this.selectedSet.forEach((employee) => {
      this.dataSet.add(employee);
      this.addedSet.delete(employee);
    });
    this.dataSet = this.onSort(this.dataSet);
    this.onSelectedChange();
  }

  onAddAll(): void {
    if (!this.dataSet.size) {
      return;
    }
    this.dataSet.forEach((employee) => {
      this.addedSet.add(employee);
    });
    this.addedSet = this.onSort(this.addedSet);
    this.dataSet.clear();
    this.onSelectedChange();
  }

  onRemoveAll(): void {
    if (!this.addedSet.size) {
      return;
    }
    this.addedSet.forEach((employee) => {
      this.dataSet.add(employee);
    });
    this.dataSet = this.onSort(new Set([...this.dataSet, ...this.addedSet]));
    this.addedSet.clear();
    this.onSelectedChange();
  }

  onSelectedChange(): void {
    this.selected = Array.from(this.addedSet).map((data) => data.value);
    this.selectedChange.emit(this.selected);
    this.selectedSet.clear();
  }

  onSort(employees: Set<Dropdown>): Set<Dropdown> {
    const employeeList = [...employees];
    return new Set(employeeList.sort((a, b) => (a.value > b.value ? 1 : -1)));
  }

  selectImportedProject(taskId: string): void {
    this.onRemoveAll();
    this.apiService
      .get<{ projects: number[] }>(
        ApiUrl.background_tasks + taskId + '/download-result/'
      )
      .subscribe(
        (res) => {
          this.selectedSet = new Set(
            res.projects
              .map((project) =>
                Array.from(this.dataSet).find((obj) => obj.value === project)
              )
              .filter((project) => project)
          );
          this.onAdd();
        },
        () => {
          this.swal.toast({ type: 'error', msg: 'Unknown Error' });
        }
      );
  }

  importProjectDropdown(uploadFile: UploadFile): void {
    const params = new HttpParams().set('client_id', this.clientId);
    this.projectService
      .importProjectDropdown(uploadFile.file[0], params)
      .subscribe(
        (res) => {
          this.importTaskId = res.task_id;
          this.progressModal.open(this.importTaskId);
        },
        () => {
          this.swal.toast({ type: 'error', msg: 'Unknown Error' });
        }
      );
  }
}
