<div class="d-flex">
  <div id="map">
    <google-map #map
        [options]="options"
        [center]="center"
        (mapClick)="clickable ? onMapClicked($event) : null">
      <map-marker *ngIf="!isDirection"
          [position]="position[0]"
          [options]="markerOptions"></map-marker>
    </google-map>
  </div>
  <div id="pano"></div>
</div>