import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { imgFormats } from './preview-image';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss'],
})
export class PreviewImageComponent {
  @ViewChild('modal') modalRef: ElementRef;
  image: string;
  openedModal: NgbModalRef;

  constructor(private modal: NgbModal) {}

  open(image?: string): void {
    this.image = image;
    this.openedModal = this.modal.open(this.modalRef, {
      centered: true,
      modalDialogClass: 'dialog-full-screen-preview-image',
    });
  }

  checkImage(url: string): boolean {
    return imgFormats.some((value) => url.includes(value));
  }
}
