import { Component, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProjectService } from 'src/app/core/services/project.service';
import { CustomFilter } from '../../models/custom-filter.model';
import { CustomSearchService } from '../../services/custom-search.service';
import { CheckInService } from 'src/app/core/services/check-in.service';

@Component({
  selector: 'app-custom-sort',
  templateUrl: './custom-sort.component.html',
  styleUrls: ['./custom-sort.component.scss'],
})
export class CustomSortComponent implements OnDestroy, IHeaderAngularComp {
  params: IHeaderParams & any;
  cellValue: string;
  ascSort = 'inactive';
  descSort = 'inactive';
  noSort = 'inactive';
  projectId: number;
  componentDestroy$ = new Subject();
  customFilter: CustomFilter;

  constructor(
    private searchService: CustomSearchService,
    private projectService: ProjectService,
    private chService: CheckInService
  ) {}

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  agInit(params: any): void {
    this.params = params;

    this.projectService.projectSubject
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((project) => !!project)
      )
      .subscribe({
        next: (project) => {
          this.projectId = project.id;
        },
      });

    this.searchService.serviceSubject.subscribe((service) => {
      this.customFilter = service;
    });

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  onSortRequested(event: any) {
    let newSort: 'asc' | 'desc' | '' = 'asc'; // Default to ascending

    if (this.params.column.isSortAscending()) {
      newSort = 'desc'; // If currently ascending, toggle to descending
    } else if (this.params.column.isSortDescending()) {
      newSort = ''; // If currently descending, remove sorting
    }

    this.params.setSort(newSort, event.shiftKey);

    const queryParams = {
      ordering:
        newSort === ''
          ? ''
          : (newSort === 'desc' ? '-' : '') + this.field.toLowerCase(),
    };
    this.params?.displayName == "Working Hour" || this.params?.displayName == "Date / Time" || (this.params?.displayName == "Status" && this.params?.column?.colDef?.field == "type") ? 
    this.chService?.querySubject?.next(queryParams) :
    this.customFilter?.service?.querySubject?.next(queryParams);
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  get field(): string {
    let keySplit = this.params.column.colDef.field;
    if (keySplit === 'month') {
      keySplit = 'start_date';
    } else if (keySplit === 'action') {
      keySplit = 'status';
    }
    return keySplit;
  }
}
