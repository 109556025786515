<div class="row row-top">
  <div class="page-carousel">{{gallery?.length ? activeIndex+1 : 0}}/{{gallery?.length}}</div>
  <button type="button"
      class="close close-button"
      (click)="closeModal.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="carousel-content">
  <svg-icon class="pointer carousel-arrow"
      src="../../../../assets/images/icons/angle-left-solid.svg"
      (click)="carousel.prev()">
  </svg-icon>
  <ngb-carousel #carousel="ngbCarousel"
      class="carousel"
      (slide)="onSlide($event)"
      [interval]="0"
      [activeId]="'slide-index-'+activeIndex"
      [showNavigationArrows]="false"
      [showNavigationIndicators]="false">
    <ng-template *ngFor="let item of gallery; index as i"
        [id]="'slide-index-'+i"
        ngbSlide>
      <div class="wrapper-carousel">
        <img class="image"
            src="{{ item }}"
            alt="image active" />
      </div>
    </ng-template>
  </ngb-carousel>
  <svg-icon class="pointer carousel-arrow"
      src="../../../../assets/images/icons/angle-right-solid.svg"
      (click)="carousel.next()">
  </svg-icon>
</div>
<div class="row-bottom">
  <div class="row-gallery">
    <div *ngFor="let item of gallery; index as i"
        class="pointer"
        (click)="onSelect(i)">
      <img class="image px-1"
          [class.active]="i === activeIndex"
          src="{{ item }}"
          alt="image gallery" />
    </div>
  </div>
</div>
