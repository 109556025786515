import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiService } from '../../../core/http/api.service';
import { ApiUrl } from '../../../core/http/api.constant';
import { BackgroundTask } from '../../models/background-task.model';
import { SweetAlertService } from '../../services/sweet-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-upload-progress-modal',
  templateUrl: './upload-progress-modal.component.html',
  styleUrls: ['./upload-progress-modal.component.scss'],
})
export class UploadProgressModalComponent implements OnDestroy {
  @Input() interval = 5; // update every n seconds
  @Input() downloadResultEnabled = false;
  @Output() done = new EventEmitter<boolean>();

  @ViewChild('progressModal') progressModal: ElementRef;

  taskId: string;
  intervalId: number;
  task: BackgroundTask;
  loading: boolean;
  loadingResult: boolean;

  constructor(
    private apiService: ApiService,
    private swal: SweetAlertService,
    private modal: NgbModal
  ) {}

  open(taskId: string): void {
    this.taskId = taskId;
    this.task = {
      status: 'PENDING',
      detail: {
        current_row: 0,
        total_row: 0,
      },
    };
    this.modal.open(this.progressModal, {
      centered: true,
      backdrop: 'static',
    });
    this.setInterval();
  }

  setInterval(): void {
    this.loading = true;
    this.intervalId = setInterval(() => {
      this.apiService
        .get<BackgroundTask>(ApiUrl.background_tasks + this.taskId + '/')
        .subscribe(
          (res) => {
            this.task = res;

            if (['SUCCESS', 'FAILURE'].includes(res.status)) {
              this.done.emit(res.status === 'SUCCESS');
              this.clearInterval();
            }
          },
          () => {
            this.loading = false;
            this.swal.toast({ type: 'error', msg: 'Unknown Error' });
          }
        );
    }, this.interval * 1000);
  }

  clearInterval(): void {
    this.loading = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  downloadResult(): void {
    this.loadingResult = true;
    const params = new HttpParams().set('type', 'import_user');
    this.apiService
      .getBlob(ApiUrl.background_tasks + this.taskId + '/download-result/', {
        params,
      })
      .subscribe(
        (res) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(res.body);
          link.download = `import_user_passwords.csv`;
          link.click();

          this.loadingResult = false;
        },
        () => {
          this.loadingResult = false;
          this.swal.toast({ type: 'error', msg: 'Unknown Error' });
        }
      );
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  get currentRow(): number {
    if (['SUCCESS', 'PENDING'].includes(this.task?.status)) {
      return 1;
    }
    if (Array.isArray(this.task?.detail)) {
      return 0;
    }

    return this.task?.detail.current_row || 0;
  }

  get totalRow(): number {
    if (['SUCCESS', 'PENDING'].includes(this.task?.status)) {
      return 1;
    }
    if (Array.isArray(this.task?.detail)) {
      return 0;
    }

    return this.task?.detail.total_row || 0;
  }

  get progressType(): string {
    if (this.task?.status === 'SUCCESS') {
      return 'success';
    }
    if (this.task?.status === 'FAILURE') {
      return 'danger';
    }

    return 'info';
  }
}
