import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../core/http/api.service';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent {
  @Input() title: string;
  @Input() templateUrl: string;
  @Input() templateFileName = 'template';
  @Input() multiple = false;
  @Input() isUploadExcel = true;
  @ViewChild('confirm') content: ElementRef;
  @Output() getFile = new EventEmitter<UploadFile>();

  files: File[] = [];
  isUploadImage: boolean;
  fileType: string;
  accept: string;

  constructor(private modal: NgbModal, private apiService: ApiService) {}

  open(type?: string): void {
    this.fileType = type;
    if (type === 'image') {
      this.title = 'ADD IMAGE';
      this.accept = 'image/*';
      this.isUploadImage = true;
    } else if (type === 'pdf') {
      this.title = 'ADD PDF';
      this.accept = '.pdf';
      this.isUploadImage = false;
    }
    this.files = [];
    this.modal
      .open(this.content, { centered: true })
      .result.then(() => {
        this.getFile.emit({
          file: this.files,
          type: this.fileType,
        });
      })
      .catch(() => {});
  }

  onGetFile(event: File[]): void {
    this.files = event;
  }

  onClickRemove(index: number): void {
    this.files.splice(index, 1);
  }

  downloadTemplate(): void {
    this.apiService.getBlob(this.templateUrl).subscribe((res) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res.body);
      link.download = `${this.templateFileName}.xlsx`;
      link.click();
    });
  }
}

export interface UploadFile {
  file: File[];
  type: string;
}
