<ng-template #modal
    let-modal>
  <div class="preview-header">
    <button type="button"
        class="close"
        (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="preview-content">
    <img class="image"
        [src]="image"
        alt="preview image" />
  </div>
</ng-template>
