import { filter, mergeMap } from 'rxjs/operators';
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProjectService } from './../services/project.service';
import { AuthenticationService } from './../authentication/authentication.service';
import { Menu, SubMenu } from './../../shared/models/menu.model';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MENU, PROFILE } from './navbar';
import { Role } from './enum/role.enum';
import {
  FieldMarketing,
  HRSolutions,
  ProjectAllowance,
} from 'src/app/shared/models/project.model';
import { Subscription } from 'rxjs';
import { RefreshProjectsService } from 'src/app/shared/services/refresh-projects.service';
import { HttpParams } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { NotificationList } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  menus: Menu[] = MENU;
  userRole: string;
  profile: Menu = PROFILE;
  active: string;
  isShow = false;
  project: number;
  projects: ProjectAllowance[];

  isLoading = true;
  subscription = new Subscription();

  hoverList = {
    notification: false,
  };
  notificationResult: any;
  showNotificationToggle = false;

  constructor(
    private authService: AuthenticationService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private refreshProjectService: RefreshProjectsService,
    private notificationService: NotificationService,
    private swal: SweetAlertService
  ) {
    this.userRole = this.authService.currentUserValue.user.role;
  }

  ngOnInit(): void {
    this.getAllNotification();
    this.active = this.menus.find((menu) =>
      menu.subMenu.find((sub) => sub.path?.includes(this.path))
    )?.name;
    this.onCheckRoleMenu();
    this.onChecktrainingRoleMenu();
    this.setProjectParam();
    this.onCheckOtRole();
    this.router.events
      .pipe(
        filter(
          (route) =>
            route instanceof NavigationEnd &&
            this.active === 'Project Menu' &&
            !this.projectParam
        )
      )
      .subscribe(() => {
        this.router.navigate([...this.paths, { project: this.project }], {
          queryParams: this.queryParams,
          replaceUrl: true,
        });
      });
    this.subscribeSubject();

    this.getProjects();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCheckRoleMenu() {
    this.menus.forEach((item) => {
      if (item.name === 'Ahead Management' || item.name === 'Ahead User') {
        item.display = !this.isAdmin && !this.isSupervisor;
      } else {
        item.display = true;
      }
    });
  }

  setProjectParam(): void {
    if (this.active !== 'Project Menu' && this.projectParam) {
      this.router.navigate([...this.paths], {
        queryParams: this.queryParams,
        replaceUrl: true,
      });
    }
  }

  get path(): string {
    return this.router.url.split(';')[0].split('/')[1];
  }

  get paths(): string[] {
    return this.router.url.split('?')[0].split(';')[0].split('/');
  }

  get projectParam(): number {
    return (
      +this.route.children[0].snapshot.paramMap.get('project') ||
      +this.router.url.split(';').toString().split('=')[1]
    );
  }

  get queryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  get name(): string {
    return this.authService.currentUserValue?.user?.first_name[0] || '-';
  }

  get selectedMenu(): string {
    return (
      this.projects.find((project) => project.id === this.project)?.name || ''
    );
  }

  selectMenu(menu: string): void {
    this.active = menu;
  }

  getProjects(): void {
    this.projectService
      .getProjectAllowanceList()
      .pipe(
        mergeMap((res) => {
          this.projects = res;
          this.project = this.projectParam || this.projects[0]?.id;
          this.setProjectParam();
          this.isLoading = false;
          return this.projectService.getProjectDetail(this.project);
        })
      )
      .subscribe((project) => {
        this.projectService.projectSubject.next(project);
        this.projectService.projectAllowanceSubject.next(
          this.currentProjectAllowance
        );
      });
  }

  get getSubMenus(): SubMenu[] {
    return this.active
      ? this.menus
          .find((menu) => menu.name === this.active)
          .subMenu.filter(
            this.filterProjectAllowance(this.currentProjectAllowance)
          )
      : this.profile.subMenu;
  }

  filterProjectAllowance(
    settingFields: HRSolutions & FieldMarketing
  ): (element: SubMenu) => boolean {
    return (element: SubMenu) => {
      if (!element.property) return true;
      if (element.property === 'merchandizer') {
        return (
          settingFields.osa_oss ||
          settingFields.sku ||
          settingFields.price_tracking ||
          settingFields.sales_report
        );
      }
      if (element.property === 'todo') {
        return settingFields.calendar && settingFields.todo
      }

      return settingFields[element.property];
    };
  }

  logout(): void {
    this.authService.logout();
    this.active = null;
    this.router.navigate(['/login']);
  }

  onProjectChange(): void {
    this.projectService.getProjectDetail(this.project).subscribe({
      next: (res) => {
        this.projectService.projectSubject.next(res);
        this.projectService.projectAllowanceSubject.next(
          this.currentProjectAllowance
        );
        this.router.navigate(['project-dashboard','hr-solution', { project: this.project }]);
      },
    });
  }

  get currentProjectAllowance(): HRSolutions & FieldMarketing {
    const currentProject = this.projects?.find(
      (currentProject) => currentProject.id === this.project
    );

    if (!currentProject) {
      return {
        check_in_check_out: false,
        roster_plan: false,
        leave_request: false,
        ot_request: false,
        upload: false,
        adjust_time: false,
        check_in_check_out_camera: false,
        check_in_check_out_location: false,
        check_in_check_out_wifi: false,
        check_in_check_out_qr_code: false,
        check_in_check_out_bluetooth: false,
        roster_plan_roster: false,
        roster_plan_day_off: false,
        upload_camera: false,
        upload_gallery: false,
        upload_file: false,
        upload_video: false,
        field_marketing: false,
        todo: false,
        track_route_pin_point: false,
        osa_oss: false,
        price_tracking: false,
        sku: false,
        sales_report: false,
        event_stock: false,
        survey: false,
        training:false,
        claims:false,
        todo_camera: false,
        calendar: false,
      };
    }

    return Object.assign(
      currentProject.hr_solutions,
      currentProject.field_marketing
    );
  }

  get isAdmin(): boolean {
    return this.userRole && this.userRole === Role.ADMIN;
  }

  get isSupervisor(): boolean {
    return this.userRole && this.userRole === Role.SUPERVISOR;
  }

  get isProjectAssignee():boolean{
    return this.userRole && this.userRole === Role.PROJECT_ASSIGNEE;
  }

  get isProjectManager():boolean{
    return this.userRole && this.userRole === Role.PROJECT_MANAGER;
  }
  get isSuperadmin():boolean{
    return this.userRole && this.userRole === Role.SUPER_ADMIN;
  }

  onCheckOtRole() {
    this.menus.find((menu) =>
      menu.subMenu.find((item) => {
        if (item.name === 'OT Dashboard') {
          item.visible = this.isSuperadmin || this.isProjectManager || this.isProjectAssignee || this.isSupervisor;
        } 
      }),
    )
  }

  onChecktrainingRoleMenu() {
    this.menus.find((menu) =>
      menu.subMenu.find((item) => {
        if (item.name === 'Training') {
          item.visible = this.isProjectManager || this.isSupervisor || this.isProjectAssignee || this.isSuperadmin;
        } 
      }),
    )
  }

  subscribeSubject(): void {
    const refreshProject = this.refreshProjectService.refreshProjects.subscribe(
      () => {
        this.projectService.getProjectAllowanceList().subscribe((res) => {
          this.projects = res;
          this.projectService.projectAllowanceSubject.next(
            this.currentProjectAllowance
          );
        });
      }
    );

    this.subscription.add(refreshProject);
  }

  getAllNotification(): void {
    this.notificationService.getAllNotification().subscribe(
      (res) => {
        this.notificationResult = res;
      },
      (err) => {
        this.swal.toast({ type: 'error', msg: err.status });
      }
    );
  }

  openNotification() {
    this.showNotificationToggle = !this.showNotificationToggle;
   
  }

  readAll() {
     this.notificationService.updateReadAll().subscribe(
      () => {
        this.ngOnInit();
      },
      (err) => {
        this.swal.toast({ type: 'error', msg: err.status });
      }
    );

  }

  goToNotification(notification: NotificationList): void {
    const type = notification.type.replace('_', '-');   
    const id = notification?.related_id?.toString();
    const projectId = notification.related_project_id;
    this.project = projectId;
    notification.read = true;
    
    this.projectService.getProjectDetail(this.project).subscribe({
      next: (res) => {
        this.projectService.projectSubject.next(res);
        this.projectService.projectAllowanceSubject.next(this.currentProjectAllowance);
  
        switch (notification.type) {
          case 'ot_request':
            this.router.navigate([type, id]).then(() => {
              this.router.navigate([type, id, 'detail', { project: this.project }]);
            });
            break;
  
          case 'roster_plan':
            this.router.navigate([type, 'request']).then(() => {
              this.router.navigate([type, 'request', id, { project: this.project }]);
            });
            break;
  
          case 'check_in_out':
            this.router.navigate(['check-in', 'all', { project: this.project }]);
            break;
  
          case 'leave_request':
            this.router.navigate(['leave-request','all', { project: this.project }]).then(() => {
              this.router.navigate(['leave-request', id]);
            });
            break;
  
          default:
            this.router.navigate(['project-dashboard','hr-solution', { project: this.project }]);
            break;
        }
      },
    });
  }
  
}
