<ng-template #modal
    let-modal>
  <div class="modal-header pb-0">
    <div class="modal-title header with-underline">
      {{ title }}
    </div>
    <button type="button"
        class="close"
        (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-content">
    <div class="py-2"
        *ngFor="let item of items; index as i">
      <label [for]="'element-'+i">
        {{ itemName }}
        <svg-icon class="pointer"
            *ngIf="items.length > 1"
            src="../../../../../assets/images/icons/delete.svg"
            triggers="manual"
            placement="right"
            (click)="deleteItem(i)">
        </svg-icon>
      </label>
      <input type="text"
          [id]="'element-'+i"
          class="form-control"
          [(ngModel)]="item.name">
    </div>
    <button class="btn btn-outline-danger btn-full mt-3"
        type="button"
        *ngIf="!singleElement"
        (click)="addItem()">
      + {{ addLabel }}
    </button>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-danger"
        type="button"
        (click)="modal.dismiss()">
      Cancel
    </button>
    <button class="btn btn-danger"
        type="button"
        (click)="onConfirm()">
      Confirm
    </button>
  </div>
</ng-template>